.div-radio-sex {
  color: #fff;
  // padding-left: 15px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
}

.anticon anticon-calendar ant-calendar-picker-icon {
  color: #fff;
}

.ant-calendar-picker-input.ant-input {
  background: transparent;
  color: #898884;
  border: 0 solid;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  color: white;
}
