.div-stage {
  background: #b1182f;
  width: 500px;
  height: 150px;
  color: #fff;
  text-align: center;
  vertical-align: middle;
}

button.ant-btn.zone-box-gold {
  background: #fcdc29;
  width: 100px;
  height: 100px;
  border-color: #fff;
  border: 1;
}

button.ant-btn.zone-box-silver {
  background: #357266;
  width: 100px;
  height: 100px;
  border-color: #fff;
  border: 1;
}

button.ant-btn.zone-box-vip {
  background: #4ca6f4;
  width: 100px;
  height: 100px;
  border-color: #fff;
  border: 1;
}
