.ticket {
  display: flex;
  position: relative;
  width: 100vh;
  height: 100vh;
  .ticket-body {
    font-family: "Kanit", sans-serif;
    box-shadow: 5px 5px 15px 5px rgba(50, 50, 50, 0.2);
    background: #23252c;
    width: 300px;
    color: #fff;
    min-height: 500px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .head {
      display: flex;
      position: relative;
      background-image: url("./images/tickets-page-header-background.jpg");
      width: 300px;
      height: 130px;
      background-size: cover;
      .event {
        font-size: 22px;
        position: absolute;
        width: 200px;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .contents {
      li {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-bottom: 8px;
      }
      ul {
        margin: 0;
        padding-left: 30px;
        padding-top: 20px;
      }
      .labels {
        color: #d8850a;
        display: block;
        font-size: 12px;
      }
      .data {
        color: #fff;
        display: block;
        font-size: 22px;
        margin-bottom: 16px;
      }
    }
    .contents-qrcode {
      padding: 10px 10px 10px 12px;
      .qrcode {
        border: #fff;
        border-style: solid;
        border-width: 12px;
      }
    }
    .footer {
      height: 50px;
      text-align: center;
    }
    .div-code-qr {
      font-size: 16px;
      color: #fff;
    }

    .contents-paid {
      text-align: center;
      font-size: 16px;
      color: #fff;
      .div-textarea {
        width: 90%;
      }
    }
  }
}
