$red: #8b0304;

.svg-style {
  margin-top: -50;
  

  text {
    pointer-events: none;
  }

  .select-table > .cls-3 {
    cursor: pointer;
  }

  .disbled-table {
    fill: black;
  }

  .cls-1,
  .cls-12,
  .cls-2 {
    fill: none;
  }

  .cls-2 {
    stroke: #fff;
    stroke-width: 0.3px;
  }

  .cls-3 {
    isolation: isolate;
  }

  .cls-22,
  .cls-4 {
    fill: #fff;
  }

  .cls-5 {
    opacity: 0.75;
  }

  .cls-10,
  .cls-5 {
    mix-blend-mode: multiply;
  }

  .cls-6 {
    fill: #010101;
    opacity: 0.2;
  }

  .cls-7 {
    fill: #fbfcfc;
  }

  .cls-8 {
    fill: #4ec6e1;
  }

  .cls-11,
  .cls-9 {
    opacity: 0.3;
    mix-blend-mode: overlay;
  }

  .cls-9 {
    fill: url(#Unnamed_Pattern_4);
  }

  .cls-10 {
    fill: #e5e8ec;
  }

  .cls-11 {
    fill: url(#Unnamed_Pattern_4-7);
  }

  .cls-12 {
    stroke: #58595b;
    stroke-miterlimit: 10;
    stroke-width: 2px;
  }

  .cls-13 {
    font-size: 32.95px;
  }

  .cls-13,
  .cls-17 {
    fill: #231f20;
  }

  .cls-13,
  .cls-17,
  .cls-22 {
    font-family: MyriadHebrew-Bold, MyriadHebrew Bold;
    font-weight: 700;
  }

  .cls-14 {
    fill: #d1d3d4;
  }

  .cls-15 {
    fill: #968d00;
  }

  .cls-16 {
    fill: #eedc00;
  }

  .cls-17,
  .cls-22 {
    font-size: 10px;
  }

  .cls-18 {
    fill: #4c7520;
  }

  .cls-19 {
    fill: #7fb539;
  }

  .cls-20 {
    fill: #8b0304;
  }

  .cls-21 {
    fill: #d81921;
  }

  .cls-23 {
    fill: #446ab3;
  }

  .disabled-table {
    fill: black;
  }
}


@media all and (max-width: 1024px) {
  .svg-style:hover {
    transform: scale(1.2);
  }
}

@media all and (max-width: 768px) {
  .svg-style:hover {
    transform: scale(1.2);
  }
}