// @import url(
//   https://fonts.googleapis.com/css?family=Open+Sans:100,
//   300,
//   400,
//   700
// );
// @import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);

body,
html {
  height: 100%;
}
// body {
//   font-family: 'Open Sans';
//   font-weight: 100;
//   display: flex;
//   overflow: hidden;
// }
input {
  ::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  ::-moz-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  :-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  &:focus {
    outline: 0 transparent solid;
    ::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.7);
    }
    ::-moz-placeholder {
      color: rgba(0, 0, 0, 0.7);
    }
    :-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.7);
    }
  }
}

.register-form {
  min-height: 10rem;
  margin: auto;
  max-width: 50%;
  width: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 3%);
  // padding: 0.5rem;
}

.login-form {
  //background: #222;
  //box-shadow: 0 0 1rem rgba(0,0,0,0.3);
  // padding-top: 15%;
  min-height: 10rem;
  margin: auto;
  max-width: 50%;
  width: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 45%);
  // padding: 0.5rem;
}
.login-text {
  //background: hsl(40,30,60);
  //border-bottom: .5rem solid white;
  color: white;
  font-size: 1.5rem;
  margin: 0 auto;
  max-width: 50%;
  padding: 0.5rem;
  text-align: center;
  //text-shadow: 1px -1px 0 rgba(0,0,0,0.3);
  .fa-stack-1x {
    color: black;
  }
}

.login-username,
.login-password {
  background: transparent;
  border: 0 solid;
  border-bottom: 1px solid rgba(white, 0.5);
  color: white;
  display: block;
  margin: 1rem;
  padding: 0.2rem;
  transition: 250ms background ease-in;
  width: calc(100% - 3rem);
  &:focus {
    background: white;
    color: black;
    transition: 250ms background ease-in;
  }
}

.login-forgot-pass {
  //border-bottom: 1px solid white;
  bottom: 0;
  color: white;
  cursor: pointer;
  display: block;
  font-size: 75%;
  left: 0;
  opacity: 0.6;
  padding: 0.5rem;
  position: absolute;
  text-align: center;
  //text-decoration: none;
  width: 100%;
  &:hover {
    opacity: 1;
  }
}
.login-submit {
  text-align: center;
  // float: left;
  width: 100px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  border: 1px solid white;
  background: transparent;
  color: white;
  display: block;
  margin: 1rem auto;
  min-width: 1px;
  padding: 0.25rem;
  transition: 250ms background ease-in;
  &:hover,
  &:focus {
    background: white;
    color: black;
    transition: 250ms background ease-in;
  }
}

.div-btn-login {
}

[class*="underlay"] {
  left: 0;
  min-height: 100%;
  min-width: 100%;
  position: fixed;
  top: 0;
}
.underlay-photo {
  animation: hue-rotate 6s infinite;
  background: url("./images/login-background.jpg");
  background-size: cover;
  -webkit-filter: grayscale(30%);
  z-index: -1;
}
.underlay-black {
  background: rgba(0, 0, 0, 0.7);
  z-index: -1;
}

@keyframes hue-rotate {
  from {
    -webkit-filter: grayscale(30%) hue-rotate(0deg);
  }
  to {
    -webkit-filter: grayscale(30%) hue-rotate(360deg);
  }
}

.div-forgot-password {
  color: #b30202;
  font-size: 14px;
  font-weight: bold;
}

.div-button-login {
  // display: flex;
  // justify-content: center;
}

.forgot-password-text {
  color: white;
  font-size: 1.5rem;
  margin: 0 auto;
  padding: 0.5rem;
  text-align: center;
  .fa-stack-1x {
    color: black;
  }
}
