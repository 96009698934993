body {
  margin: 60%;
  background: #111;
}

.theatre {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
  padding-left: 10%;
}

.theatre-div {
  overflow-x: auto;
  width: 100%;
}

.state {
  // display: flex;
  position: absolute;
  transform: translate(0%, -100%);
  // margin: 0 auto;
  text-align: center;
  border-radius: 5px;
  background-color: #8ba4ea;
  width: 89%;
  height: 40px;
  // color: #5a5b5a;
}
.state-new {
  text-align: center;
  width: 89%;
}
.state-font {
  margin: auto;
  font-size: 20px;
  color: #fff;
}
.seat-font {
  position: absolute;
  padding: 4px 16px;
  font-size: 18px;
  // color: #000;
}
.cinema-seats {
  display: flex;
}
.cinema-seats .seat {
  cursor: pointer;
}
.cinema-seats .seat:hover:before {
  content: "";
  position: absolute;
  width: 60px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.cinema-seats .seat.active:before {
  content: "";
  position: absolute;
  width: 60px;
  height: 40px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
}

.left .cinema-row {
  /* transform: skew(-5deg); */
  margin: 0 6px;
}
.left .seat {
  width: 60px;
  height: 40px;
  border-radius: 5px;
  /* background: linear-gradient(to top, #761818, #761818, #761818, #761818, #761818, #B54041, #F3686A); */
  /* margin-bottom: 10px; */
  /* transform: skew(5deg); */
  margin-top: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.zone-a {
  float: left;
  background: linear-gradient(
    to top,
    #761818,
    #761818,
    #761818,
    #761818,
    #761818,
    #b54041,
    #f3686a
  );
}
.zone-b {
  background: linear-gradient(
    to top,
    #d35400,
    #d35400,
    #d35400,
    #d35400,
    #d35400,
    #e67e22,
    #e67e22
  );
}
.zone-c {
  background: linear-gradient(
    to top,
    #2a7ffa,
    #2a7ffa,
    #2a7ffa,
    #2a7ffa,
    #2a7ffa,
    #6ca7fc,
    #b5d2fb
  );
}
// auiaoun
.div-theatre-new {
  position: relative;
  justify-content: space-between;
}
.div-seat-row-new {
  display: flex;
  padding-left: 2px;
  // justify-content: center;
  // @include BREAKPOINT(M) {
  // justify-content: flex-start;
  // }
  .span-seat {
    padding-right: 5px;
    flex: 1;
  }
  .black {
    cursor: pointer;
    // width: 40px;
    height: 30px;
    font-size: 11px;
    border-radius: 5px;
    vertical-align: middle;
    margin-top: 15px;
    text-align: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    color: #b1b1b1;
    -webkit-filter: grayscale(100%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
    background: #f0f0f0;
    filter: grayscale(100%); /* FF 35+ */
  }
  .red {
    cursor: pointer;
    // width: 40px;
    height: 30px;
    font-size: 13px;
    border-radius: 5px;
    margin-top: 15px;
    text-align: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    color: #fff;
    background: linear-gradient(
      to top,
      #761818,
      #761818,
      #761818,
      #761818,
      #761818,
      #b54041,
      #f3686a
    );
  }
  .red:hover {
    background: linear-gradient(
      to top,
      #767676,
      #767676,
      #767676,
      #777777,
      #999999,
      #999999,
      #acacac
    );
    // -webkit-filter: grayscale(100%);
    // /* Ch 23+, Saf 6.0+, BB 10.0+ */
    // filter: grayscale(100%); /* FF 35+ */
  }
  .yellow {
    cursor: pointer;
    // width: 40px;
    height: 30px;
    font-size: 13px;
    border-radius: 5px;
    margin-top: 15px;
    text-align: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    color: #fff;
    background: linear-gradient(
      to top,
      #bfa300,
      #bfa300,
      #d2b300,
      #d2b300,
      #e1bf00,
      #f9d300,
      #ffdc17
    );
    // background: linear-gradient(
    // to top,
    // #d35400,
    // #d35400,
    // #d35400,
    // #d35400,
    // #d35400,
    // #e67e22,
    // #e67e22
    // );
  }
  .yellow:hover {
    background: linear-gradient(
      to top,
      #767676,
      #767676,
      #767676,
      #777777,
      #999999,
      #999999,
      #acacac
    );
    // -webkit-filter: grayscale(100%);
    // /* Ch 23+, Saf 6.0+, BB 10.0+ */
    // filter: grayscale(100%);
    // /* FF 35+ */
  }
  .pink {
    cursor: pointer;
    // width: 40px;
    height: 30px;
    font-size: 13px;
    border-radius: 5px;
    margin-top: 15px;
    text-align: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    color: #fff;
    background: linear-gradient(
      to top,
      #aa006a,
      #aa006a,
      #d30082,
      #d30082,
      #c4007a,
      #c4007a,
      #c4007a
    );
  }
  .pink:hover {
    background: linear-gradient(
      to top,
      #767676,
      #767676,
      #767676,
      #777777,
      #999999,
      #999999,
      #acacac
    );
  }
  .green {
    cursor: pointer;
    // width: 40px;
    height: 30px;
    font-size: 13px;
    border-radius: 5px;
    margin-top: 15px;
    text-align: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    color: #fff;
    background: linear-gradient(
      to top,
      #005500,
      #005500,
      #005500,
      #006200,
      #006200,
      #008200,
      #008200
    );
  }
  .green:hover {
    background: linear-gradient(
      to top,
      #767676,
      #767676,
      #767676,
      #777777,
      #999999,
      #999999,
      #acacac
    );
  }
  .blue {
    cursor: pointer;
    // width: 40px;
    height: 30px;
    font-size: 13px;
    border-radius: 5px;
    margin-top: 15px;
    text-align: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    color: #fff;
    background: linear-gradient(
      to top,
      #2a7ffa,
      #2a7ffa,
      #2a7ffa,
      #2a7ffa,
      #2a7ffa,
      #6ca7fc,
      #b5d2fb
    );
  }
  .blue:hover {
    background: linear-gradient(
      to top,
      #767676,
      #767676,
      #767676,
      #777777,
      #999999,
      #999999,
      #acacac
    );
    // -webkit-filter: grayscale(100%);
    // /* Ch 23+, Saf 6.0+, BB 10.0+ */ // filter: grayscale(100%); // /* FF 35+ */
  }
  .span-seat-fountain {
    flex: 1;
  }
  .fountain-left {
    height: 100%;
    width: 100%;
    margin-top: 15px;
    background: #00ffff;
  }
  .fountain-right {
    height: 100%;
    width: 90%;
    margin-top: 15px;
    margin-right: 10px;
    background: #00ffff;
  }
  .fountain-bottom {
    height: 100%;
    width: 100%;
    margin-right: 10px;
    background: #00ffff;
  }
  .fountain-bottom-right {
    height: 100%;
    width: 90%;
    margin-right: 10px;
    background: #00ffff;
  }
}
.ant-upload.ant-upload-select-picture-card {
  margin-left: 10px !important;
  width: 280px !important;
  height: 250px !important;
}
