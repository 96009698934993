.event-image {
  padding: 10px;
  > a {
    overflow: hidden;
    display: inline-block;
    &:hover img {
      transform: scale(1.05);
    }
    img {
      max-width: 100%;
      max-height: 100%;
      display: inline-block;
      transform: scale(1);
      transition: all 0.4s ease-in-out;
    }
  }
}
